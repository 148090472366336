<template>
	<div class="section" id="planos">
		<div class="container-background bg-color-secondary"></div>
		<div class="container heading-row bg-color-secondary border-bottom-0 mx-auto mb-0">
			<div class="heading-text-wrap">
				<h4 class="text-white">
					Escola, temos planos especiais para a sua Instituição.
				</h4>
			</div>
		</div>
		<div class="gray-container bg-color-gray-700">
			<div class="container">
				<div class="left-side">
					<h5>
						Leve o Pazzei <br class="mobile-break">para os seus alunos
					</h5>
					<p>
						Já imaginou oferecer uma plataforma que ajuda os seus alunos a conseguir a aprovação nos principais
						vestibulares do país?
					</p>
					<p>
						O Pazzei é uma aplicação completa para seus alunos montarem listas de exercícios com questões resolvidas e
						comentadas. Além disso, acompanhe o desempenho dos seus alunos na resolução de exercícios e direcione melhor
						o conteúdo que precisa ser trabalhado nas revisões.
					</p>
					<div class="pricing-features-wrapper">
						<h6>
							Confira as funcionalidades do Plano Escola
						</h6>
						<div class="pricing-features border-0 bg-transparent">
							<ul>
								<li><i class="icon-check-circle text-color-green me-3"></i>Cadastros de alunos, professores e turmas</li>
								<li><i class="icon-check-circle text-color-green me-3"></i>Relatórios de performance por aluno</li>
								<li><i class="icon-check-circle text-color-green me-3"></i>Listas on-line compartilhadas num clique</li>
								<li><i class="icon-check-circle text-color-green me-3"></i>+150 títulos de vestibulares de todo o Brasil</li>
								<li><i class="icon-check-circle text-color-green me-3"></i>+150.000 questões disponíveis</li>
								<li><i class="icon-check-circle text-color-green me-3"></i>Questões resolvidas e comentadas</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="right-side">
					<form 
						action=""
						method="post"
						class="bg-color-yellow"
					>
						<fieldset>
							<div class="legend-wrapper">
								<legend class="text-secondary-2">Quero conhecer</legend>
								<span>Informe seus dados para contato.</span>
							</div>
							
							<label for="name">Nome*</label>
							<input type="text" id="name" v-model="form.name" @change="validateInput">

							<label for="email">Email*</label>
							<input type="text" id="email" v-model="form.email" @change="validateInput">

							<label for="phone">Celular*</label>
							<div class="phone-wrapper">
								<vue-tel-input id="phone" v-model="form.phone" v-mask="['+## (##) ####-####', '+## (##) #####-####']" @change="validateInput"/>
							</div>

							<label for="school">Instituição / Escola*</label>
							<input type="text" id="school" v-model="form.school" @change="validateInput">

							<label for="role-at-school">Sua atividade na escola</label>
							<input type="text" id="role-at-school" v-model="form.roleAtSchool">

							<label for="sum-verify">6 + 6 = ?</label>
							<input type="text" id="sum-verify" v-model="form.sumVerify" @change="validateSum">
						</fieldset>
						<div class="btn-content">
							<a
								class="button button-rounded nott button-circle"
								:class="sentFormData ? 'sent-form-data' : ''"
								target="_blank"
								@click="submitForm"
								>{{ sentFormData  ? 'Enviado!' : 'Enviar'}}</a>
						</div>
					</form>
				</div>
			</div>
		</div>
		<CustomersPartnersComponent/>
	</div>
</template>

<script>
import CustomersPartnersComponent from '@/components/CustomersPartnersComponent.vue';
import { mask } from 'vue-the-mask';
import axios from 'axios';

export default {
	name: 'FeaturesComponent',
	directives: { mask },
	data() {
		return {
			portal_url: process.env.VUE_APP_PORTAL_URL,
			emailRegexPattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
			form: {
				name: "",
				email: "",
				phone: "",
				school: "",
				roleAtSchool: "",
				sumVerify: "",
			},
			sentFormData: false,
		}
	},
	components: {
		CustomersPartnersComponent,
	},
	methods: {
		async submitForm(e) {
      e.preventDefault();
			
			if (this.sentFormData || !this.isFormValidated()) {
				return;
			}

      axios({
				method: 'post',
				url: process.env.VUE_APP_PAZZEI_USERS_BASE_URL + '/landingFormData',
				data: this.form,
			}).then(() => {
				this.sentFormData = true;
			}).catch((error) => {
				this.sentFormData = false;
				console.log(error)
			})
    },
		isFormValidated() {
			const invalidFields = document.getElementsByClassName('invalid-input');
			return invalidFields.length === 0;
		},
		validateEmail(text, e) {
			!this.emailRegexPattern.test(text)
				? e.target.classList.add("invalid-input")
				: e.target.classList.remove("invalid-input")
    },
		validateInput(e) {
      const value = e.target.value;

			if (!value || !value.length) {
				e.target.classList.add("invalid-input");
			}

			if (e.target.id.toLowerCase() === 'email') {
				this.validateEmail(value, e);
			}
    },
		validateSum(e) {
			parseInt(e.target.value) !== 12
				? e.target.classList.add("invalid-input")
				: e.target.classList.remove("invalid-input")
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
	padding: 0;
	background-color: var(--white);
}

.container-background {
	height: 30%;
	width: 100%;
	position: absolute;
	z-index: 0;
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.heading-row {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 196px;
}

.heading-text-wrap {
	display: flex;
	justify-content: right;
	align-items: center;
	height: 100%;
}

h4 {
	text-align: center;
	margin: 0;
	font-family: 'public-sans';
	font-weight: 700;
}

.gray-container {
	position: relative;
	height: 613px;
	padding: 63px 0 0 0;
}

.gray-container .left-side * {
	color: var(--white);
}

.left-side {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.right-side {
	position: relative;
}

h5 {
	letter-spacing: -0.02em;
	text-align: center;
	margin: 0;
	font-size: 24px;
}

p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px !important;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
}

h6 {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
}

.pricing-features li {
	padding: 4px 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.pricing-features li i {
	font-size: 24px !important;
	width: 24px;
	height: 24px;
	border-radius: 24px;
	color: var(--white);
	text-align:center;
	background: var(--white);
}

form {
	width: 460px;
	max-width: 460px;
	border-radius: 8px;
	padding: 46px 26px;
	position: sticky;
	z-index: 999;
}

form * {
	font-family: 'public-sans' !important;
}

form fieldset {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.legend-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

form legend {
	font-size: 35px;
	font-weight: 400;
	line-height: 43px;
	letter-spacing: 0em;
	text-align: center;
	margin: 0;
}

form span {
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: center;
	color: var(--gray-700);
	width: 100%;
	margin-bottom: 20px;
}

form label {
	text-transform: none;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--gray-700);
	width: 100%;
	margin-bottom: 5px;
}

form label:not(:first-of-type) {
	margin-top: 15px;
}

.invalid-input {
	border: 2px solid #DC3545;
}

input, #phone {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border: none;
	border-radius: 4px;
	padding: .5rem;
	width: 100%;
	height: 32px;
	box-shadow: 0px 2px 3px 0px #DDDDDD;
	font-size: 15px;
	color: var(--gray-700);
}

form .phone-wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
}

form #phone {
	padding: 0;
}

.btn-content {
	display: flex;
	justify-content: center;
}

.btn-content .button {
	width: 200px;
	text-align: center;
	user-select: none;
	padding: 8px 73.5px;
}

.sent-form-data {
	background-color: var(--gray-700);
	cursor: auto;
	padding-left: 0;
	padding-right: 0;
}

.mobile-break {
	display: none;
}

@media (max-width: 670px) {
	.mobile-break {
		display: block;
	}

	.row {
		padding: 0;
	}

	.heading-row {
		flex-direction: column;
		gap: .5rem;
	}

	.heading-text-wrap,
	.button-wrap {
		width: 100%;
		justify-content: center;
		padding: 0;
	}

	.button-wrap {
		display: flex;
	}

	.container-background {
		display: none;
	}

	.container {
		flex-direction: column;
	}

	.gray-container {
		height: 100%;
	}

	form {
		width: 100%;
	}
}

@media (min-width: 992px) {
	h4 {
		text-align: left;
		font-size: 30px;
		font-weight: 700;
		line-height: 38px;
	}

	h5 {
		text-align: left;
		font-size: 36px;
		font-weight: 700;
		line-height: 44px;
	}
}

@media (max-width: 1367px) {
	.left-side {
		max-width: 554px;
	}
}
</style>
