<template>
  <HeroComponent/>
  <section id="content">
    <div class="content-wrap p-0">
      <AboutComponent/>
      <FeaturesComponent/>
      <SliderComponent/>
      <ContentTabComponent/>
      <PricesComponent/>
      <FormComponent/>
      <ReviewsComponent/>
      <SocialProof/>
      <FaqComponent/>
    </div>
  </section>
</template>

<script>
import HeroComponent from '@/components/HeroComponent.vue';
import AboutComponent from '@/components/AboutComponent.vue';
import FeaturesComponent from '@/components/FeaturesComponent.vue';
import SliderComponent from '@/components/SliderComponent.vue';
import ContentTabComponent from '@/components/ContentTabComponent.vue';
import PricesComponent from '@/components/PricesComponent.vue';
import FormComponent from '@/components/FormComponent.vue';
import ReviewsComponent from '@/components/ReviewsComponent.vue';
import SocialProof from '@/components/SocialProofComponent.vue'
import FaqComponent from '@/components/FaqComponent.vue';

export default {
  name: "HomePage",
  components: {
    HeroComponent,
    AboutComponent,
    FeaturesComponent,
    SliderComponent,
    ContentTabComponent,
    PricesComponent,
    FormComponent,
    ReviewsComponent,
    SocialProof,
    FaqComponent,
  }
}
</script>

<style scoped>

</style>
