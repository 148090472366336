<template>
  <header id="header" class=" dark">
		<div id="header-wrap">
			<div class="container">
				<div class="header-row justify-content-lg-between">

					<div id="logo" class="py-3">
						<a href="/" class="standard-logo" data-dark-logo="../assets/logo.png"><img src="../assets/logo.png" alt="Pazzei Logo"></a>
						<a href="/"  class="retina-logo" data-dark-logo="../assets/logo.png"><img src="../assets/logo.png" alt="Pazzei Logo"></a>
					</div>

					<div id="primary-menu-trigger">
						<svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
					</div>

					<nav class="primary-menu">
						<ul class="menu-container one-page-menu" data-easing="easeInOutExpo" data-speed="1250" data-offset="100">
							<li class="menu-item"><a class="menu-link link link-1" href="https://lp.pazzei.com.br/plano-escola-pazzei" target="_blank"><div>Plano Escola</div></a></li>
							<li class="menu-item">
								<router-link
									to="/politicas-de-privacidade"
									class="menu-link link link-1"
									>Política de privacidade
								</router-link>
							</li>			
							<li class="menu-item"><a class="menu-link link link-1" href="https://pazzei.notion.site/Central-de-ajuda-Pazzei-b52cfa08e06546378af5bdde0fc790d0" target="_blank"><div>Central de Ajuda</div></a></li>							
							<li class="menu-item"><a class="menu-link link link-1" href="#" data-bs-toggle="modal" data-bs-target=".bs-modal-rd-station"><div>Contato</div></a></li>
							<li class="menu-item"> <div class=""><a :href="`${portal_url}cadastro`" target="_blank" class="button button-rounded nott button-circle create-account-btn">Criar conta</a></div> </li>
							<li class="menu-item"><a :href="`${portal_url}login`" target="_blank" class="button button-rounded nott button-circle login-btn">Entrar</a></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
		<div class="header-wrap-clone"></div>
	</header>

	<!-- RD Station Contato -->
	<div class="modal fade bs-modal-rd-station" id="modal-rd-station" tabindex="-1" role="dialog" aria-labelledby="centerModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">Entre em contato conosco</h6>
					
					<button type="button" class="btn-close btn-sm" data-bs-dismiss="modal" aria-hidden="true"></button>
				</div>
				<div class="modal-body">
					<div class="form-widget block-contact-1">
						<div class="form-result"></div>
						<form class="mb-0" id="contactform" name="contactform" action="#" method="#">
							<div class="row">
								<div class="col-12 form-group mb-5">
									<label for="contactform-name">Nome: <small class="text-danger">*</small></label>
									<input v-model="name" type="text" id="contactform-name" name="contactform-name" class="form-control required"/>
								</div>

								<div class="col-12 form-group mb-5">
									<label for="contactform-phone">Celular: <small class="text-danger">*</small></label>
									<input v-model="phone" type="text" id="contactform-phone" name="contactform-phone" class="form-control required" />
								</div>

								<div class="w-100"></div>

								<div class="col-12 form-group mb-5">
									<label for="contactform-email">E-mail: <small class="text-danger">*</small></label>
									<input v-model="email" type="email" id="contactform-email" name="contactform-email" class="required email form-control"/>
								</div>

								<div class="col-12 form-group mb-5">
									<label for="contactform-mensagem">Mensagem: <small class="text-danger">*</small></label>
									<textarea v-model="mensagem" type="text" id="contactform-mensagem" name="contactform-mensagem" class="form-control required"></textarea>
								</div>

								<div class="w-100"></div>

								<button id="notify-msg" data-notify-type="success" data-notify-msg="<i class='icon-ok-sign me-1'></i> Recebemos seu contato! Obrigado!" onclick="SEMICOLON.widget.notifications({ el: this }); return false;"></button>
								<div class="col-12 form-group mb-0">
									<button  @click="cleanForm" class="float-end button button-rounded button-large nott button-circle" type="submit" id="contactform-submit" name="contactform-submit" value="">
										Enviar mensagem<i class="bi-arrow-right"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import {TheMask} from 'vue-the-mask'
export default {
	name: 'HeadComponent',
	data: {
		name: '',
		phone: '',
		email: '',
		mensagem: ''
	},
	components: {TheMask},
	metaInfo: {
      script: [
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.15/jquery.mask.min.js', async: true, defer: true }
      ],
    },
	data () {
		return {
			portal_url: process.env.VUE_APP_PORTAL_URL,
		}
	},
	methods: {
		cleanForm() {
			if(this.name && this.phone && this.email && this.mensagem){
				$('#modal-rd-station').modal('hide');
				$('#notify-msg').click();
			}
		}
	},
	mounted() {
		document.addEventListener('DOMContentLoaded', function () {
			$('#contactform-phone').mask('(00) 00000-0000');
		});
	},
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.button {
		text-align: center;
		padding: 8px 32px;
	}
	
	.create-account-btn {
		width: 158px;
	}

	.login-btn {
		width: 118px;
	}

	#notify-msg{
		display: none !important;
	}
	.modal-header h6{
		font-size: 1.25rem;
		font-weight: 700;
	}

	.block-contact-1 .form-control {
		padding: 12px 2px;
		background-color: transparent !important;
		border-top-width: 0;
		border-right-width: 0;
		border-left-width: 0;
		border-bottom-width: 2px;
		border-radius: 0;
		font-weight: 500;
		font-size: 18px;
	}

	.block-contact-1 .form-control::-moz-placeholder {
		color: #CCC;
		font-weight: 400;
		font-size: 15px;
	}
	.block-contact-1 .form-control:-ms-input-placeholder {
		color: #CCC;
		font-weight: 400;
		font-size: 15px;
	}
	.block-contact-1 .form-control::-webkit-input-placeholder {
		color: #CCC;
		font-weight: 400;
		font-size: 15px;
	}

	.block-contact-1 textarea.form-control {
		resize: none;
		overflow: hidden;
		word-wrap: break-word;
	}

	.block-contact-1 label:not(.custom-control-label) {
		text-transform: none;
		letter-spacing: 0;
		font-weight: 500;
		color: #777;
		margin-bottom: 3px;
		font-size: 14px;
	}

	.block-contact-1 .custom-control-label::before,
	.block-contact-1 .custom-control-label::after { top: 0.0875rem; }

	.block-contact-1 .form-control:active,
	.block-contact-1 .form-control:focus {
		border-color: #400376;
	}

	.custom-control-input:checked ~ .custom-control-label::before {
		border-color: #400376;
		background-color: #400376;
	}

	.custom-control-input:focus ~ .custom-control-label::before {
		box-shadow: 0 0 0 0.2rem #40037615;
	}

	.block-contact-1 .social-icon {
		background-color: rgba(0,0,0,0.2);
		font-size: 16px;
	}
</style>
