<template>
  <p class="paragraph-text" v-html="paragraphText"></p>
</template>

<script>
  export default {
    name: 'ParagraphItem',
    props: {
      paragraphText: String,
    }
  }
</script>

<style scoped>
.paragraph-text > a {
  color: #0000EE !important;
  text-decoration: underline;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin: 0;
}

@media (max-width: 670px) {
  p {
    text-align: justify;
  }
}

</style>
