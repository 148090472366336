<template>
  <div class="input-wrapper">
    <input
      type="checkbox"
      :id="checkboxId"
      :value="checkboxValue"
      :name="checkboxName"
      :selected="isSelected"
      @change="onSelectCheckbox, $emit('update:modelValue', this.value)"
      v-model="value"
    >
    <label :for="checkboxId">{{ checkboxValue }}</label>
  </div>
</template>

<script>
export default {
  name: 'InputCheckbox',
  data() {
    return {
      value: false,
    }
  },
  props: {
    checkboxId: String,
    checkboxValue: String,
    checkboxName: String,
    isSelected: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    onSelectCheckbox(e) {
      this.$emit("changed-checkbox", e.target.id, this.value);
    }
  }
}
</script>

<style scoped>
* {
  font-family: "rubik";
  font-weight: 400;
  color: #777777;
}

fieldset {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.direction-row {
  flex-direction: row;
}

.direction-column {
  flex-direction: column;
}

.input-wrapper {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
}

input {
  margin-top: 4px;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 4px;
  flex-shrink: 0;
  border: 1px solid var(--gray-300);
  width: 16px;
  height: 16px;
}

input:hover {
  border-color: var(--primary);
  background-color: #F2EBF4;
}

input:checked {
  border-color: var(--primary);
  background-color: #F2EBF4;
}

input:checked::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../assets/icons/checkbox-selected.svg");
  background-size: 10px 8px;
  background-repeat: no-repeat;
  background-position: center;
}

label {
  text-transform: none;
  font-size: 16px;
  text-align: left;
  letter-spacing: normal;
  margin: 0
}

</style>
