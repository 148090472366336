<template>
  <div class="paragraph-wrapper">
    <h4 v-if="paragraphHeading">
      {{ paragraphHeading }}
    </h4>
    <paragraph-item 
      v-for="(paragraph, index) in paragraphArray"
      :key="index"
      :paragraphText=paragraph
    />
    <unordered-list
      v-if="list && list.type === 'unordered'"
      :type="list.type"
      :title="list.title"
      :list-content="list.content"
    />
  </div>
</template>

<script>
import ParagraphItem from '@/components/util/ParagraphItem'
import UnorderedList from './UnorderedList.vue'

export default {
  name: 'HeadingWithParagraph',
  props: {
    paragraphHeading: String,
    paragraphArray: Array,
    list: Object,
  },
  components: {
    ParagraphItem,
    UnorderedList,
  }
}
</script>

<style scoped>
.paragraph-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.paragraph-wrapper:not(:first-of-type) {
  margin-top: 24px;
}

h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
}
</style>