<template>
  <div class="input-wrapper">
    <label :for="selectId"><span v-if="isRequired">* </span>{{ selectLabel }}</label>
    <select 
      :name="selectName"
      :id="selectId"
      class="form-select"
      :class="invalidSelect ? 'invalid-select' : ''"
      @change="validateSelect"
    >
      <option value="" disabled selected>{{ selectPlaceholder }}</option>

      <option
        v-for="(item, index) in selectOptions" 
        :key="index"
        :value="item"
      >{{ item }}</option>
    </select>
    <span class="error-message" v-if="invalidSelect">
      {{ errorMessage || requiredFieldMessage}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'InputSelect',
  data() {
    return {
      selectInput: false,
      invalidSelect: false,
    }
  },
  props: {
    selectId: String,
    selectLabel: String,
    selectName: String,
    selectPlaceholder: String,
    selectOptions: Array,
    isRequired: Boolean,
    modelValue: String
  },
  emits: ['update:modelValue'],
  methods: {
    validateSelect(e) {
      if (!this.isRequired) { return }
      this.onSelectOption(e);
      
      const value = e.target.value;
      
      if (!value.length) {
        this.invalidSelect = true;
        return;
      }
      
      this.invalidSelect = false;
    },
    onSelectOption(e) {
      this.$emit('update:modelValue', e.target.value);

      if (e.target.value === "Outro (especifique abaixo)") {
        this.$emit('selectedOtherSelect', true);
        return;
      }

      this.$emit('selectedOtherSelect', false);
    }
  }
}
</script>

<style scoped>
* {
  font-family: "rubik";
  font-weight: 400;
  color: #777777;
}

label {
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  letter-spacing: normal;
}

label span {
  color: #DC3545;
}

select {
  width: 100%;
  border: 2px solid var(--bs-gray-400);
  border-radius: 5px;
  padding: 14px 10px;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../assets/icons/select-box-chevron.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
}

select.invalid-input {
  border-bottom: 2px solid #DC3545;
}

select > option {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;
}

.error-message {
  font-size: 14px;
  line-height: 28px;
  color: #DC3545;
}
</style>
