<template>
  <fieldset
    :class="(inlineFieldset ? 'direction-row' : 'direction-column')"
    :style="styleObject"
  >
    <div
      v-for="(item, index) in inputRadioArray" 
      :key="index"
      class="input-wrapper"
    >
      <input
        type="radio"
        :id="item.radioId"
        :value="item.radioValue"
        :name="item.radioName"
        :selected="item.isSelected"
        @change="onSelectRadio"
      >
      <label :for="item.radioId">{{ item.radioValue }}</label>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'InputRadioGroup',
  props: {
    styleObject: Object,
    inputRadioArray: Array,
    inlineFieldset: Boolean,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    onSelectRadio(e) {
      this.$emit('update:modelValue', e.target.value);
      
      if (e.target.id === "other-radio") {
        this.$emit('selectedOtherRadio', true);
        return;
      }

      this.$emit('selectedOtherRadio', false);
    }
  }
}
</script>

<style scoped>
* {
  font-family: "rubik";
  font-weight: 400;
  color: #777777;
}

fieldset {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.direction-row {
  flex-direction: row;
}

.direction-column {
  flex-direction: column;
}

.input-wrapper {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

input {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid var(--gray-300);
  width: 16px;
  height: 16px;
}

input:hover {
  border-color: var(--primary);
  background-color: #F2EBF4;
}

input:checked {
  border-color: var(--primary);
  background-color: #F2EBF4;
}

input:checked::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../assets/icons/radio-selected.svg");
  background-size: 6.5px;
  background-repeat: no-repeat;
  background-position: center;
}

label {
  text-transform: none;
  font-size: 16px; 
  text-align: left;
  letter-spacing: normal;
  margin: 0
}

</style>
