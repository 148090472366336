<template>
	<div class="section text-gray-700" id="about">
		<div class="container">
			<div class="row">
				<div class="col heading-wrap">
					<h3>
						O Pazzei vai ajudar você a conquistar sua aprovação
					</h3>
					<p>
						Tenha acesso a mais de 150 mil questões para se preparar para o Enem e vestibulares de todo o Brasil.
					</p>
					<p>
						Monte listas de exercícios personalizadas com os conteúdos de cada matéria, resolva provas de exames
						anteriores e monitore o seu desempenho com relatórios de erros e acertos.
					</p>
				</div>
				<div class="col  iframe-wrap">
					<iframe height="283px" src="https://www.youtube-nocookie.com/embed/lfCvBPRH6HQ" title="Conheça o Pazzei!"
						class="rounded-5" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AboutComponent',
	data() {
		return {
			portal_url: process.env.VUE_APP_PORTAL_URL,
		}
	},
}
</script>

<style scoped>
#about {
	padding: 0;
	background-color: #FFFFFF;
	background-size: auto 100%;
	font-family: 'public-sans';
}

.heading-wrap {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	row-gap: 20px;
}

h3 {
	text-align: left;
	font-family: 'public-sans';
	font-size: 26px;
	font-weight: 700;
	margin: 0;
}

p {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px !important;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
}

.iframe-wrap {
	margin: 80px 0;
	display: flex;
	justify-content: flex-end;
}

iframe {
	border-radius: 8px;
	height: 283px;
	width: 484px;
}

@media (max-width: 670px) {
	#about {
		background-position: -150px;
	}
	
	.container>.row {
		flex-direction: column;
		gap: 0;
		justify-content: center;
		--bs-gutter-x: 0 !important;
	}

	h3 {
		text-align: center;
	}
}

@media (min-width: 992px) {
	.container {
		padding-left: 30px;
	}

	.heading-wrap {
		max-width: 553px;
		margin: 99px 0 0 65px;
	}

	h3 {
		font-size: 36px;
		font-weight: 700;
		line-height: 44px;
		letter-spacing: -0.02em;
	}
}
</style>