<template>
  <ul>
    <h5 v-html="title"></h5>
    <li
      v-for="(item, index) in listContent"
      :key="index"
      v-html="item"
    ></li>
  </ul>
</template>

<script>
  export default {
    name: 'DefaultList',
    props: {
      type: String,
      title: String,
      listContent: Array,
    }
  }
</script>

<style scoped>
h5 {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}

li {
  margin-left: 1.25rem;
}
</style>
