<template>
  <HeaderComponent/>
  <router-view></router-view>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import './static/animate.css'
import './static/bootstrap.css'
import './static/sliders.css'
import './static/font-icons.css'
import './static/style.css'
import './static/font.css'
import './static/colors.css'
import './static/swiper.css'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
  },
}
</script>

