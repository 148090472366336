<template>
  <div class="hero-section bg-color-primary" id="hero">
    <div class="container">
      <div class="row">
        <div class="col heading-wrap">
          <h2 class="text-white">
            A plataforma para você treinar para o Enem e vestibular
          </h2>
        </div>
        <div class="col app-images-wrap">
          <img class="mockup" src="../../public/images/hero-container/mockup-group.svg" alt="progresso de questões">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroComponent",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero-section {
  height: 630px;
}

.container {
  height: 100%;
}

.row {
  gap: 87px;
  height: 100%;
}

h2 {
  font-size: 28px;
  text-align: center;
}

.app-images-wrap {
  position: relative;
  height: 100%;
}

.mockup {
  position: absolute;
  z-index: 99;
}

@media (max-width: 670px) {
  .heading-wrap {
    padding: 1rem;
  }

  .container > .row {
    flex-direction: column;
    gap: 0;
    justify-content: center;
  }

  .col {
    flex: 0 0 0%;
  }

  .mockup {
    position: relative;
  }
}

@media (max-width: 992px) {

}

@media (min-width: 992px) {
  .heading-wrap {
    padding: 138px 0;
    max-width: 426px;
  }

  .app-images-wrap {
    padding: 34px 0 0;
  }

  h2 {
    line-height: 60px;
    font-size: 48px;
    font-weight: 700;
    word-spacing: -5px;
  }  
}
</style>