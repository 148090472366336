<template>
  <section>
    <div class="container customers-and-partners-container">
      <h5>Clientes e Parceiros</h5>
    </div>
    <div id="customers-partners-slide">
      <div 
        class="swiper-controls-wrapper"
        v-if="!isMobile"
      >
        <div 
          class="swiper-button-prev"
          @click="prev"
        ></div>
        <div 
          class="swiper-button-next"
          @click="next"
        ></div>
      </div>
      <swiper
        @swiper="onSwiper"
        :slides-per-view="1"
        :speed="70"
        :pagination="{
          clickable: true,
        }"
        :loop="true"
        :loopedSlides="3"
        :effect="'fade'"
        :modules="modules"      
        class="mySwiper">
        <swiper-slide class="desktop-slide" style="display: flex; gap: 44.67px;">
          <div class="bg-slide slider-1">
          </div>
          <div class="bg-slide slider-2">
          </div>
          <div class="bg-slide slider-3">
          </div>
          <div class="bg-slide slider-4">
          </div>
        </swiper-slide>
    
        <swiper-slide class="desktop-slide" style="display: flex; gap: 44.67px;">
          <div class="bg-slide slider-5">
          </div>
          <div class="bg-slide slider-6">
          </div>
          <div class="bg-slide slider-7">
          </div>
          <div class="bg-slide slider-8">
          </div>
        </swiper-slide>
    
        <swiper-slide class="desktop-slide" style="display: flex; gap: 44.67px;">
          <div class="bg-slide slider-9">
          </div>
          <div class="bg-slide slider-10">
          </div>
          <div class="bg-slide slider-11">
          </div>
          <div class="bg-slide slider-12">
          </div>
        </swiper-slide>
      </swiper>

      <swiper
        :slides-per-view="1"
        :space-between="44.67"
        :pagination="{
          clickable: true,
        }"
        :loop="true"
        :loopedSlides="12"
        :effect="'fade'"
        :modules="modules"      
        class="mySwiper mobile-swiper">
        <swiper-slide>
          <div class="bg-slide slider-1">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-2">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-3">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-4">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-5">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-6">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-7">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-8">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-9">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-10">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-11">
          </div>
        </swiper-slide>
    
        <swiper-slide>
          <div class="bg-slide slider-12">
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Navigation, Pagination } from "swiper";

export default {
  name: "CustomersPartnersComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Autoplay, Pagination],
    };
  },
  data() {
    return {
      portal_url: process.env.VUE_APP_PORTAL_URL,
      enem_module:
        "https://conteudo.pazzei.com.br/modulo-enem?utm_source=site&utm_medium=referral&utm_campaign=Modulo+Enem",
      size: null,
      sliderTransform: null,
      swiper: null,
    };
  },
  computed: {
    isMobile() {
      return this.size > 768 ? false : true;
    },
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    checkIfIsMobile() {
      this.size = window.innerWidth;
    }
  },
  mounted() {
    this.checkIfIsMobile();
    window.addEventListener("resize", this.checkIfIsMobile);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkIfIsMobile);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mobile-swiper {
  display: block;
}

.mySwiper:not(.mobile-swiper) {
  display: none;
}

@media (min-width: 768px) {
  .mobile-swiper {
    display: none;
  }
  
  .mySwiper:not(.mobile-swiper) {
    display: block;
  }
}

section {
  padding-bottom: 100px;
  background-color: var(--white);
}

.customers-and-partners-container {
  margin: 100px auto 80px auto;
}

h5 {
  font-family: 'public-sans';
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin: 0;
}

#customers-partners-slide {
  position: relative;
}

.swiper {
  width: 83%;
  height: 235.44px;
}

.bg-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 135.44px;
  padding: 16px 24px 16px 24px;
  border-radius: 16px;
  border: 3px solid var(--gray-200);
  gap: 10px;
}

.slider-1 {
  background: url("../assets/sliders/customers-container/prefeitura-maringa.png") no-repeat center center;
  background-size: 200px 103.44px;
}

.slider-2 {
  background: url("../assets/sliders/customers-container/grupo-integrado.png") no-repeat center center;
  background-size: 194px 102px;
}

.slider-3 {
  background: url("../assets/sliders/customers-container/super-professor.png") no-repeat center center;
  background-size: 175px 52px;
}

.slider-4 {
  background: url("../assets/sliders/customers-container/amentoria.png") no-repeat center center;
  background-size: 150px 74.23px;
}

.slider-5 {
  background: url("../assets/sliders/customers-container/bio-explica.png") no-repeat center center;
  background-size: 175px 72.5px;
}

.slider-6 {
  background: url("../assets/sliders/customers-container/ozilda.png") no-repeat center center;
  background-size: 175px 86.75px;
}

.slider-7 {
  background: url("../assets/sliders/customers-container/forca-maxima.png") no-repeat center center;
  background-size: 193px 73.98px;
}

.slider-8 {
  background: url("../assets/sliders/customers-container/x2.png") no-repeat center center;
  background-size: 96px 96px;
}

.slider-9 {
  background: url("../assets/sliders/customers-container/criarte.png") no-repeat center center;
  background-size: 174px 51px;
}

.slider-10 {
  background: url("../assets/sliders/customers-container/x-tri.png") no-repeat center center;
  background-size: 106px 106px;
}

.slider-11 {
  background: url("../assets/sliders/customers-container/espartanos.png") no-repeat center center;
  background-size: 105px 104px;
}

.slider-12 {
  background: url("../assets/sliders/customers-container/curso-evolucao.png") no-repeat center center;
  background-size: 175px 69px;
}

@media (max-width: 670px) {
  .customers-and-partners-container {
    margin: 60px auto 30px auto;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .bg-slide {
    width: 100%;
    border-radius: 0;
    border: none;
  }

  .btn-content {
    height: 315px !important;
  }

  .btn-content .button {
    padding: 4px 12px !important;
    font-size: 0.7rem !important;
  }

  .slider-1,
  .changeable-slider,
  .slider-2,
  .slider-3,
  .slider-4 {
    width: 100% !important;
  }

  .swiper-pagination {
    margin-top: .5rem;
  }
}

@media (min-width: 992px) {
  h5 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    text-align: left;
  }
}
</style>
