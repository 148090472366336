import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const globalOptions = {
  mode: 'international',
  defaultCountry: 'BR',
  dropdownOptions: {
    showSearchBox: false,
  },
  inputOptions: {
    placeholder: '',
    type: String,
    showDialCode: true,
  }
};

const app = createApp(App);
app.use(VueTelInput, globalOptions);
app.use(router);
app.mount('#app')
