<template>
  <div
    class="accordion-item">
    <h5 class="mb-0" :id="'heading' + order">
      <button
        class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + order"
        aria-expanded="false" :aria-controls="'collapse' + order">
        <i v-if="hasIconCheck" class="icon-check"></i> {{ buttonText }}
      </button>
    </h5>
    <div :id="'collapse' + order" class="accordion-collapse collapse" :aria-labelledby="'heading' + order"
      :data-bs-parent="'#' + accordionId">
      <div class="accordion-body" v-html="accordionBody">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AccordionItem",
    props: {
      buttonText: String,
      accordionBody: String,
      order: String,
      hasIconCheck: Boolean,
      accordionId: String,
    }
  }
</script>

<style>

</style>