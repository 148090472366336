import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/home-page.vue'
import PrivacyView from '@/pages/PrivacyView.vue'
import ThermsView from '@/pages/ThermsView.vue'
import PrivacyRightsView from '@/pages/PrivacyRightsView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/politicas-de-privacidade',
    name: 'politicas-de-privacidade',
    component: PrivacyView
  },
  {
    path: '/termos-de-uso',
    name: 'termos-de-uso',
    component: ThermsView
  },
  {
    path: '/direitos-de-privacidade',
    name: 'direitos-de-privacidade',
    component: PrivacyRightsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
