<template>
  <div class="section" id="faq">
    <div class="container clearfix">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="faq-container">
            <h5>Perguntas frequentes</h5>
          </div>

          <div class="accordion accordion-flush" id="accordionExample">
            <div class="accordion-item">
              <h5 class="mb-0" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFive" aria-controls="collapseFive">
                  Quantas listas posso montar? Posso resolver quantas listas eu quiser?
                </button>
              </h5>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Não há restrições quanto ao número de listas que você pode fazer. Aproveite a liberdade de usar a
                  plataforma conforme sua necessidade e conveniência.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseSix" aria-controls="collapseSix">
                  Como vou conseguir acompanhar meus resultados?
                </button>
              </h5>
              <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Quando você terminar uma lista, é só ir até a área de relatórios de desempenho para ver como você se
                  saiu.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingSeven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  Questões de quais instituições eu posso encontrar na plataforma?
                </button>
              </h5>
              <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Você vai ter acesso a questões de 150 instituições diferentes sempre atualizadas, abrangendo
                  vestibulares de todo o país, provas militares e concursos de olimpíadas do Ensino Fundamental.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingEight">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  Se eu assinar hoje, terei acesso a atualizações de novas provas e questões?
                </button>
              </h5>
              <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Todos os assinantes têm acesso às atualizações de questões. No Pazzei, estamos constantemente
                  atualizando as questões para garantir que você esteja sempre atualizado com os conteúdos dos
                  vestibulares e do Enem.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h5 class="mb-0" id="headingNine">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                  Tenho tempo de garantia?
                </button>
              </h5>
              <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  De acordo com o direito de consumidor, você tem 7 dias de garantia a partir da data da sua compra.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingTen">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                  Quais métodos de pagamento são aceitos?
                </button>
              </h5>
              <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Você pode pagar com cartão de crédito ou via pix.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingEleven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                  Como funciona a assinatura do Pazzei?
                </button>
              </h5>
              <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Os planos disponíveis são de assinatura mensal ou semestral. Os planos mensais, são pagos mês a mês,
                  no mesmo dia da contratação, ou seja, contratando-se dia 15, a cobrança será todo dia 15, via cartão
                  de crédito, com assinatura recorrente. Já o plano semestral será pago à vista ou parcelado via cartão
                  de crédito em até 6x sem juros sendo que neste caso, em virtude do valor promocional ofertado, não há
                  devolução dos valores pagos e nem cancelamento do respectivo plano antes do término do período
                  contrato, sendo que o serviço permanecerá à disposição do usuário durante todo o tempo previsto para o
                  plano.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="mb-0" id="headingTwelve">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                  Posso migrar do plano Mensal para o Semestral?
                </button>
              </h5>
              <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Com certeza! Você pode fazer essa migração a qualquer momento.
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqComponent',
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
  background-color: var(--white);
  padding-top: 40px !important;
}

h5 {
  font-family: 'public-sans';
  font-weight: 700;
  text-align: left;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin: 0;
  margin-bottom: 40px;
}

.accordion-body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.accordion-button {
  line-height: 1.5rem;
}

@media (min-width: 992px) {
  .section {
    padding-top: 102.44px !important;
    padding-bottom: 100px !important;
  }

  h5 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }
}
</style>
