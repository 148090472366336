<template>
  <section id="privacy">
    <div class="content-wrap p-0">
      <div class="container">
        <h2>
          Direito de privacidade
        </h2>
        <p>
          Nos termos do art. 18 da LGPD, você pode requerer informações e correções de seus dados preenchendo o
          formulário abaixo, conforme nossa <router-link to="/politicas-de-privacidade" class="privacy-therms-link">Política de Privacidade</router-link>.
        </p>
        <form class="privacy-rights-form">
          <fieldset>
            <input-text
              :input-id="'privacy-form-name-input'"
              :is-required="true"
              :input-label="'Nome:'"
              :input-type="'name'"
              v-model="form.inputName"
              @change="validateInput"
            />

            <input-text
              :input-id="'privacy-form-email-input'"
              :is-required="true"
              :input-label="'Email:'"
              :input-type="'email'"
              :validate="true"
              :error-message="'Email inválido'"
              v-model="form.inputEmail"
              @change="validateInput"
            />

            <input-select
              @selectedOtherSelect="selectedOtherSelect"
              :select-id="selectComponent.selectId"
              :select-label="selectComponent.selectLabel"
              :select-placeholder="selectComponent.selectPlaceholder"
              :select-options="selectComponent.selectOptions"
              :is-required="true"
              :error-message="'Você deve selecionar uma opção válida'"
              v-model="form.selectedSubject"
            />

            <input-text
              :input-id="'privacy-form-other-subject-input'"
              :is-required="true"
              :input-label="'Outro:'"
              v-if="selectComponent.otherSelectIsSelected"
              v-model="form.inputOtherSubject"
              @change="validateInput"
            />

            <input-text
              :input-id="'privacy-form-especify-input'"
              :is-required="true"
              :input-label="'Especifique'"
              v-if="selectComponent.otherSelectIsSelected"
              v-model="form.inputSpecify"
              @change="validateInput"
            />

            <input-radio-group
              @selectedOtherRadio="selectedOtherRadio"
              :styleObject="radioComponent.styleObject"
              :inline-fieldset="radioComponent.inlineFieldset"
              :input-radio-array="radioComponent.inputRadioArray"
              v-model="form.userType"
            />

            <input-text
              :input-id="'privacy-form-other-user-input'"
              :is-required="true"
              :input-label="'Outro:'"
              v-if="radioComponent.otherRadioIsSelected"
              v-model="form.otherUserType"
            />

            <div class="checkbox-wrapper">
              <legend class="required-field-legend">*Campo obrigatório</legend>
              <input-checkbox
                v-for="(item, index) in checkboxComponent.inputCheckboxArray"
                :key="index"
                :checkbox-value="item.checkboxValue"
                :checkbox-name="item.checkboxName"
                :checkbox-id="item.checkboxId"
                v-model="form[item.checkboxName]"
              />
            </div>
          </fieldset>

          <div class="recaptcha-wrapper">
            <div id="recaptcha_container"></div>
          </div>

          <div class="button-wrapper">
            <button
                class="button button-rounded nott button-circle"
                :class="sentFormData ? 'sent-form-data' : ''"
                @click="submitForm">
              {{ sentFormData  ? 'Enviado!' : 'Enviar'}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import InputText from '@/components/util/InputText.vue';
import InputRadioGroup from '@/components/util/InputRadioGroup.vue';
import InputCheckbox from '@/components/util/InputCheckbox.vue';
import InputSelect from '@/components/util/InputSelect.vue';
import axios from "axios";
/* eslint-disable */
export default {
  name: 'PrivacyRightsView',
  components: {
    InputText,
    InputRadioGroup,
    InputCheckbox,
    InputSelect,
  },
  data() {
    return {
      sentFormData: false,
      emailRegexPattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      sitekey: process.env.VUE_APP_PAZZEI_RECAPTCHA_SITE_URL,
      checkboxComponent: {
        inputCheckboxArray: [
          { checkboxValue: "Declaro que todas as informações prestadas são verdadeiras, assumindo a responsabilidade civil, criminal e administrativa pelas mesmas.", checkboxName: "agreedLegalCharges", checkboxId: "legal-charges-checkbox" },
          { checkboxValue: "Declaro que li e concordo com os termos da Política de Privacidade e Proteção de Dados da Super Professor", checkboxName: "agreedTherms", checkboxId: "agreed-therms-checkbox" },
        ]
      },
      selectComponent: {
        otherSelectIsSelected: false,
        selectId: "privacy-form-subject-select",
        selectLabel: "Assunto",
        selectPlaceholder: "Selecione uma opção",
        selectOptions: [
          "Saber quais informações minhas são processadas aqui",
          "Corrigir informações ou dados incorretos",
          "Solicitar a exclusão dos meus dados",
          "Saber onde/com quem meus dados são compartilhados",
          "Outro (especifique abaixo)",
        ]
      },
      radioComponent: {
        otherRadioIsSelected: false,
        styleObject: {
          maxWidth: "522px",
          gap: "12px"
        },
        inlineFieldset: true,
        inputRadioArray: [
          { radioValue: "Cliente", radioName: "user-type", radioId: "client-radio" },
          { radioValue: "Funcionário ou prestador de serviços", radioName: "user-type", radioId: "staff-radio" },
          { radioValue: "Fornecedor", radioName: "user-type", radioId: "supplier-radio" },
          { radioValue: "Leitor do blog", radioName: "user-type", radioId: "reader-radio" },
          { radioValue: "Outro (especificar)", radioName: "user-type", radioId: "other-radio" },
        ]
      },
      form: {
        inputName: null,
        inputEmail: null,
        selectedSubject: null,
        inputOtherSubject: '',
        inputSpecify: null,
        userType: null,
        otherUserType: '',
        agreedLegalCharges: false,
        agreedTherms: false,
      },
    }
  },
  methods: {
    selectedOtherRadio(value) {
      this.radioComponent.otherRadioIsSelected = value;
    },
    selectedOtherSelect(value) {
      this.selectComponent.otherSelectIsSelected = value;
    },
    async submitForm(e) {
      e.preventDefault();

      if (this.sentFormData || !this.isFormValidated()) {
        return;
      }

      if (!this.validateUserAgreements()) {
        return;
      }

      axios({
        method: 'post',
        url: process.env.VUE_APP_PAZZEI_USERS_BASE_URL + '/dpoFormData',
        data: this.form,
      }).then(() => {
        this.sentFormData = true;
      }).catch((error) => {
        this.sentFormData = false;
        console.log(error)
      })
    },
    validateUserAgreements() {
      return this.form.agreedLegalCharges && this.form.agreedTherms;
    },
    isFormValidated() {
      const invalidFields = document.getElementsByClassName('invalid-input');
      return invalidFields.length === 0;
    },
    validateEmail(text, e) {
      !this.emailRegexPattern.test(text)
          ? e.target.classList.add("invalid-input")
          : e.target.classList.remove("invalid-input")
    },
    validateInput(e) {
      const value = e.target.value;

      if (!value || !value.length) {
        e.target.classList.add("invalid-input");
      }

      if (e.target.id.toLowerCase() === 'email') {
        this.validateEmail(value, e);
      }
    },
  },
  mounted() {
    window.onloadCallback = () => {
      grecaptcha.render("recaptcha_container", {
        sitekey: this.sitekey,
        action: "verify",
        callback: (token) => {
          window.recaptcha_token = token;
        },
      });
    };

    let recaptchaScript = document.createElement("script");

    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
    );

    recaptchaScript.setAttribute("async", "true");
    recaptchaScript.setAttribute("defer", "true");
    document.head.appendChild(recaptchaScript);
  }
}
</script>

<style scoped>
.container {
  max-width: 726px;
  padding: 80px 0 112px 0;
}

h2 {
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
}

p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.privacy-rights-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  padding: 0 16px;
}

.privacy-rights-form > fieldset {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 38px;
}

legend {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #DC3545;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 642px;
}

.recaptcha-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 22px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  width: 117px;
  height: 40px;
}

@media (max-width: 670px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .privacy-rights-form  {
    padding: 0;
  }
}
.sent-form-data {
  background-color: var(--gray-700);
  cursor: auto;
  padding-left: 0;
  padding-right: 0;
}

.invalid-input {
  border: 2px solid #DC3545;
}

@media (min-width: 992px) {
  h2 {
    line-height: 42px;
    font-size: 28px;
  }
}
</style>
