<template>
  <div class="input-wrapper">
    <label :for="inputId">{{ inputLabel }} <span v-if="isRequired">*</span></label>
    <input 
      type="text"
      :id="id"
      :placeholder="inputPlaceholder"
      :maxlength="inputMaxLength"
      :class="invalidInput ? 'invalid-input' : ''"
      @change="validateInput"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <span class="error-message" v-if="invalidInput">
      {{ errorMessage || requiredFieldMessage}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  data() {
    return {
      invalidInput: false,
      requiredFieldMessage: "Este campo é obrigatório",
      emailRegexPattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    }
  },
  props: {
    inputId: String,
    inputPlaceholder: String,
    inputMaxLength: String,
    isRequired: Boolean,
    inputLabel: String,
    inputType: String,
    validate: Boolean,
    errorMessage: String,
    modelValue: String
  },
  emits: ['update:modelValue'],
  methods: {
    validateInput(e) {
      const text = e.target.value;

      if (!this.isRequired) { return }

      this.validateInputTextLength(text);

      if (!this.validate) { return }
      
      if (this.inputType.toLowerCase() === 'email') {
        this.validateEmail(text);
      }
    },
    validateInputTextLength(text) {
      if (!text.length) {
        this.invalidInput = true;
        return;
      }

      this.invalidInput = false;
    },
    validateEmail(text) {
      if (!this.emailRegexPattern.test(text)) {
        this.invalidInput = true;
        return;
      }

      this.invalidInput = false;
    }
  }
}
</script>

<style scoped>
* {
  font-family: "rubik";
  font-weight: 400;
  color: #777777;
}

label {
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  letter-spacing: normal;
}

label span {
  color: #DC3545;
}

input {
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid var(--bs-gray-400);
  padding: 14px 10px;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}

input.invalid-input {
  border-bottom: 2px solid #E42C3E;
}

.error-message {
  font-size: 14px;
  line-height: 28px;
  color: #E42C3E;
}
</style>
