<template>
	<div class="section">
		<div class="container">
			<div class="row content-row heading-block-wrapper justify-content-between">
				<h4 class="nott mb-3">Veja o que estão falando do Pazzei</h4>
				<div class="user-reviews-wrapper">
					<div class="review-box">
						<p>“Utilizei muito o Pazzei no meu último ano de ensino médio seja para provas bimestrais ou vestibulares.
							Resolver exercícios é uma das melhores formas de aprender e memorizar conteúdos e com a ajuda do Pazzei
							procurar questões objetivas e de somatória ficou mais fácil, além disso com as opções de escolher
							exercícios por tema e por instituição deixou minhas listas mais completas.”</p>
						<span class="review-author">Bruna</span>
					</div>
					<div class="review-box">
						<p>“O Pazzei é uma plataforma que verdadeiramente proporciona aos estudantes uma jornada educacional
							completa! Através da plataforma, os alunos têm acesso a uma vasta biblioteca de questões, permitindo se
							preparem de forma abrangente para enfrentar os desafios de provas de diversas universidades do país. O
							Pazzei se destaca como um guia confiável no caminho do desenvolvimento acadêmico, oferecendo recursos
							valiosos para o sucesso estudantil.”</p>
						<span class="review-author">Patrícia</span>
					</div>
					<div class="review-box">
						<p>“Fico feliz por ter conquistado o que eu tanto almejei, e me sinto grata pelo auxílio que recebi da
							plataforma Pazzei, que foi importante em momentos de meus estudou e auxiliou para a prova de vestibular
							UEM. A plataforma é muito bem estruturada e te ajuda no aperfeiçoamento de muitos conteúdos importantes.
							Desde já, agradeço.”</p>
						<span class="review-author">Luiza</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReviewsComponent',
	data() {
		return {
			portal_url: process.env.VUE_APP_PORTAL_URL,
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
	padding: 0 0 80px 0;
	background-color: var(--white);
}

.content-row {
	justify-content: space-between;
	width: 100%;
	margin: 0;
}

.heading-block-wrapper {
	padding: 0;
}

.heading-block-wrapper>h4 {
	font-family: 'public-sans';
	font-weight: 700;
	letter-spacing: -0.02em;
	text-align: center;
}

.user-reviews-wrapper {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	gap: 19px;
}

.review-box {
	display: flex;
	flex-direction: column;
	flex: 1 1 0px;
	align-self: stretch;
	justify-content: space-between;
	padding: 32px;
	border-radius: 16px;
	border: 2px var(--primary) solid;
	gap: 24px;
}

.review-box>p {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px !important;
	text-align: left;
	color: var(--gray-500);
}

.review-box>span {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--primary);
}

@media (max-width: 670px) {
	.heading-block-wrapper>h4 {
		font-size: 24px;
	}

	.user-reviews-wrapper {
		flex-wrap: wrap;
	}

	.review-box {
		flex: 1 1 100%;
	}
}

@media (min-width: 992px) {
	.heading-block-wrapper>h4 {
		font-size: 36px;
		line-height: 44px;
	}
}
</style>
