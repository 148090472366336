<template>
  <swiper
    :pagination="{
      dynamicBullets: false,
      clickable: true,
    }"
    :autoplay="{
      delay: 10000,
      disableOnInteraction: true,
    }"
    :loop="true"
    :navigation="true"
    :effect="'fade'"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide>
      <div class="bg-slide slider-1">
        <div class="btn-content">
          <a
            class="button button-rounded nott button-circle"
            :href="`${portal_url}cadastro${enem_module}`"
            target="_blank"
            >Quero agora
          </a>
        </div>
      </div>
    </swiper-slide>

    <swiper-slide>
      <div class="bg-slide slider-2">
        <div class="btn-content">
          <a
            class="button button-rounded nott button-circle"
            :href="enem_module"
            target="_blank"
            >Quero agora
          </a>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "../static/teste.css";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { onMounted, onUnmounted, ref } from "vue";

export default {
  name: "SliderComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const innerWidth = ref(window.innerWidth);
    const hasImages = ref(false);
    const imgSliderDesktop = `${process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL}advertising/main-advertising-landing-desktop.jpg`;
    const imgSliderMobile = `${process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL}advertising/main-advertising-landing-mobile.jpg`;
    const setNewTab = () => {
      innerWidth.value = window.innerWidth;
    };
    window.addEventListener("resize", setNewTab);
    onUnmounted(() => window.removeEventListener("resize", setNewTab));

    const getBackgroundImage = () => {
      return innerWidth.value < 992
        ? `url(${imgSliderMobile})`
        : `url(${imgSliderDesktop})`;
    };

    onMounted(async () => {
      try {
        hasImages.value =
          (await checkImageAvailability(imgSliderDesktop)) &&
          (await checkImageAvailability(imgSliderMobile));
      } catch (error) {
        console.error("Error checking image availability:", error);
      }
    });

    const checkImageAvailability = (imgSrc, timeout = 5000) => {
      return new Promise((resolve) => {
        const img = new Image();

        const onLoad = () => {
          img.onload = null;
          img.onerror = null;
          clearTimeout(timer);
          resolve(true);
        };

        const onError = () => {
          img.onload = null;
          img.onerror = null;
          clearTimeout(timer);
          resolve(false);
        };

        const timer = setTimeout(() => {
          img.onload = null;
          img.onerror = null;
          resolve(false);
        }, timeout);

        img.onload = onLoad;
        img.onerror = onError;
        img.src = imgSrc;
      });
    };

    return {
      getBackgroundImage,
      hasImages,
      modules: [Pagination, Navigation, EffectFade, Autoplay],
    };
  },
  data() {
    return {
      portal_url: process.env.VUE_APP_PORTAL_URL,
      enem_module:
        "https://conteudo.pazzei.com.br/modulo-enem?utm_source=site&utm_medium=referral&utm_campaign=Modulo+Enem",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 670px) {
  .btn-content {
    height: 315px !important;
  }
  .btn-content .button {
    padding: 4px 12px !important;
    font-size: 0.7rem !important;
  }
  .slider-0,
  .slider-1,
  .changeable-slider,
  .slider-2 {
    height: 350px !important;
  }
  .swiper-pagination {
    margin-top: .5rem;
  }
}

@media (max-width: 992px) {
  .btn-content {
    height: 580px;
  }
  .btn-content .button {
    padding: 8px 29.5px;
  }
  .slider-1 {
    background: url("../assets/sliders/slider-mobile-01.jpg") no-repeat center
      center;
    background-size: cover;
    height: 630px;
    width: 100%;
  }
  .slider-2 {
    background: url("../assets/sliders/slider-mobile-02.jpg") no-repeat center
      center;
    background-size: cover;
    height: 630px;
    width: 100%;
  }
  .changeable-slider {
    background-size: cover;
    height: 630px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .btn-content {
    height: 580px;
  }
  .btn-content .button {
    padding: 8px 29.5px;
  }

  .slider-1 {
    background: url("../assets/sliders/slider-01.jpg") no-repeat center center;
    background-size: cover;
    height: 630px;
    width: 100%;
    text-align: left;
  }
  .slider-2 {
    background: url("../assets/sliders/slider-02.jpg") no-repeat center center;
    background-size: cover;
    height: 630px;
    width: 100%;
    text-align: left;
  }
  .changeable-slider {
    background-size: cover;
    height: 630px;
    width: 100%;
    text-align: left;
  }
}

.btn-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
}
</style>
