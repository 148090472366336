<template>
  <div class="section" id="prova-social">
    <div class="container clearfix">
      <div class="row">
        <div class="col information-wrapper">
          <div class="content-box">
            <span>
              +8.473
            </span>
            <p>
              Estudantes e escolas fazendo parte da história do Pazzei.
            </p>
          </div>
          <div class="content-box">
            <span>
              +8.636
            </span>
            <p>
              Listas online geradas por nossos estudantes e professores.
            </p>
          </div>
          <div class="content-box">
            <span>
              +150 mil
            </span>
            <p>
              Questões resolvidas, comentadas e validadas estão disponíveis no Pazzei.
            </p>
          </div>
        </div>

        <div class="col news-wrapper">
          <h4>Pazzei na mídia</h4>
          <article>
            <a class="article-row" target="_blank" href="https://oglobo.globo.com/patrocinado/dino/noticia/2024/02/29/acao-leva-curso-pre-vestibular-gratuito-a-jovens-de-maringa.ghtml">
              <div class="article-col">
                <img class="news-image" src="../../public/images/social-container/o-globo-article-image.png" alt="imagem do artigo publicado em O Globo">
              </div>
              <div class="article-col content-col">
                <img class="portal-logo" src="../../public/images/social-container/logotipo-o-globo.png" alt="logotipo O Globo" height="18px" width="80px">
                <p>
                  Ação leva curso pré-vestibular gratuito a jovens de Maringá
                </p>
                <time datetime="2024-02-29">29 de fevereiro de 2024</time>
              </div>
            </a>
          </article>
          <article>
            <a class="article-row" target="_blank" href="https://bluestudio.estadao.com.br/agencia-de-comunicacao/releases/releases-geral/pazzei-lanca-modulo-do-enem-para-ajudar-estudantes-na-prova/">
              <div class="article-col">
                <img class="news-image" src="../../public/images/social-container/estadao-article-image.png" alt="imagem do artigo publicado no Estadão">
              </div>
              <div class="article-col content-col">
                <img class="portal-logo" src="../../public/images/social-container/logotipo-estadao.png" alt="logotipo Estadão" height="18px" width="80px">
                <p>
                  Pazzei lança módulo do Enem para ajudar estudantes na prova
                </p>
                <time datetime="2024-01-24">24 de janeiro de 2024</time>
              </div>
            </a>
          </article>
          <article>
            <a class="article-row" target="_blank" href="https://www.terra.com.br/noticias/pazzei-cria-modelo-de-estudo-para-alunos-do-ensino-medio,295c4a5b3add5fc7a06214d543b7c684iy482jix.html">
              <div class="article-col">
                <img class="news-image" src="../../public/images/social-container/terra-article-image.png" alt="imagem do artigo publicado no Terra">
              </div>
              <div class="article-col content-col">
                <img class="portal-logo" src="../../public/images/social-container/logotipo-portal-terra.png" alt="logotipo Terra" height="22px" width="80px">
                <p>
                  Pazzei cria modelo de estudo para alunos do Ensino Médio
                </p>
                <time datetime="2023-12-14">14 de dezembro de 2023</time>
              </div>
            </a>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentTabComponent',

  data: function () {
    return {
      isShowCollapse1: true,
      isShowCollapse2: false,
      isShowCollapse3: false,
      isShowCollapse4: false,
    };
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.section {
  padding-top: 51px;
  padding-bottom: 28.56px;
  background-color: #FFFFFF;
}

h4 {
  font-size: 24px;
  margin: 0;
}

.content-box:not(:first-of-type) {
  padding-top: 40px;
}

.content-box:not(:last-of-type) {
  border-bottom: 2px solid var(--gray-200);
  padding-bottom: 40px;
}

.content-box > span {
  color: var(--primary);
  font-family: "inter";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
}

.content-box > p {
  color: var(--gray-700);
  font-family: "public-sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

.news-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

article {
  max-width: 435px;
}

.article-row {
  display: flex;
  gap: 8px;
}

.article-row .article-col:not(.content-col) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.article-row > .content-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

article .news-image {
  min-width: 165px;
  min-height: 130px;
  border-radius: 8px;
}

article a {
  text-decoration: none;
  color: unset; 
}

article a p {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px !important;
  text-align: left;
  margin: 0;
}

article a time {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--gray-500);
}

@media (max-width: 670px) {
  .container > .row {
    gap: 3rem;
  }

  article a p {
    font-size: 16px;
  }

  article a time {
    font-size: 14px;
  }
}

@media (min-width: 670px) {
  .information-wrapper {
    padding-left: 146px
  }
}

@media (min-width: 992px) {
  .section::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 0 24px 0 0;
    background-color: var(--light-blue-400);
    width: 14.34846%;
    height: 100%;
    top: 0;
  }

	.container {
		padding-left: 30px;
	}

  .content-box {
    width: 430px;
  }

  .content-box > p {
    width: 362px;
  }

  h4 {
    font-family: "inter";
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}
</style>
